.base-input {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  &__text-space-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    order: 2;
    height: 100%;

    &.has-prefix-icon {
      .base-input__text-space {
        padding-left: 41px;
      }
    }
  }

  &__icon {
    position: absolute;
    display: flex;
    left: 13px;
  }

  &__icon-right {
    position: absolute;
    display: flex;
    right: 13px;
  }

  &__select {
    order: 2;
    width: 100%;
    position: relative;

    &__icon {
      position: absolute;
      display: flex;
      left: 13px;
      top: 14px;
    }

    &.has-prefix-icon {
      .base-input__text-space {
        padding-left: 41px;
      }
    }

    select {
      appearance: none;
      width: 100%;
      padding-right: 48px;

      &:invalid {
        color: var(--black3);
        font-weight: 300;
      }
    }

    &__arrow {
      position: absolute;
      top: 14px;
      right: 16px;
    }
  }

  &__datalist {
    order: 2;
    width: 100%;
    position: relative;

    &__icon {
      position: absolute;
      display: flex;
      left: 13px;
      top: 14px;
    }

    &.has-prefix-icon {
      .base-input__text-space {
        padding-left: 41px;
      }
    }

    select {
      appearance: none;
      width: 100%;
      padding-right: 48px;

      &:invalid {
        color: var(--black3);
        font-weight: 300;
      }
    }

    &__arrow {
      position: absolute;
      top: 14px;
      right: 16px;
    }
  }
  &__text-space {
    padding: 13px 16px;
    border: 1px solid #e2e4e8;
    border-radius: 8px;
    font-size: 14px;
    transition: 0.2s ease-out;
    width: 100%;
    height: 100%;

    &[disabled] {
      background: #eeeff2;
    }

    &::placeholder {
      color: var(--black3);
      font-weight: 300;
    }

    &:focus {
      border: 1px solid var(--in-orange);
    }
  }

  &__label {
    order: 1;
    font-size: 14px;
    margin-bottom: 4px;
    color: var(--black);
    font-weight: 300;
  }

  &__error {
    order: 3;
    font-size: 12px;
    margin-top: 8px;
    color: var(--red);
  }

  &__file-upload {
    position: relative;
    border: 1px dashed #003b7733;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 22px;
    border-radius: 8px;

    &-container {
      order: 2;
      margin-top: 8px;
    }

    p {
      color: var(--black2);
      font-size: 14px;
      max-width: 212px;
      text-align: center;
    }

    input {
      opacity: 0;
      height: 100%;
      width: 100%;
      position: absolute;
    }

    &__icon {
      margin-right: 8px;
    }
  }

  &--otp {
    input {
      display: block;
      height: 50px !important;
      width: 50px !important;
      border: 1px solid #d9dce0;
      border-radius: 8px;
      font-size: 16px;

      &:focus {
        border: 1px solid var(--in-orange);
      }

      &::placeholder {
        color: transparent;
      }

      &:not(input:placeholder-shown) {
        background: #f6f7f9;
      }
    }
  }

  &__base-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
    margin-bottom: 16px;

    span {
      font-size: 14px;
    }
  }
}

.base-switch {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: fit-content;
  cursor: pointer;

  &__track {
    width: 40px;
    height: 24px;
    border-radius: 100vh;
    display: block;
    background: var(--in-orange);
    transition: 0.2s ease;
  }

  &__thumb {
    height: 20px;
    width: 20px;
    background: #ffff;
    border-radius: 100vh;
    box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.1);
    position: absolute;
    left: 18px;
    top: 2px;
    transition: 0.2s ease;
  }

  input {
    position: absolute;
    top: 0;
    opacity: 0;

    &:not(input:checked) {
      & + div {
        .base-switch__thumb {
          left: 2px;
        }

        .base-switch__track {
          background: var(--gray);
        }
      }
    }

    &:disabled {
      & + div {
        .base-switch__thumb,
        .base-switch__track {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }
  }
}
