@import "./mixins";

.base-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 16px 24px;
  border-radius: 8px;
  cursor: pointer;
  line-height: 1;
  font-weight: 400;

  transition: 0.2s ease-out;
  transition-property: transform;

  &.w-fit {
    width: fit-content;
  }
  &:active {
    transform: scale(0.9);
  }

  &-suffix-icon,
  &-prefix-icon {
    display: flex;
    align-items: center;
  }

  &-suffix-icon {
    margin-left: 8px;
  }

  &-prefix-icon {
    width: 20px;
    height: 20px;
    @include desktop {
      margin-right: 4px;
    }
  }

  &--clear {
    padding: 0;
    background: transparent;
    transition: 0.2s ease-out;
    transition-property: transform;

    &:active {
      transform: scale(0.9);
    }
  }

  &--primary {
    @extend .base-btn;
    color: #fff;
    letter-spacing: -0.4px;
    font-size: 16px;
    background-color: var(--in-orange);
  }

  &--alternate {
    @extend .base-btn--primary;
    color: var(--in-orange);
    background-color: transparent;
    border: 1px solid var(--in-orange);
  }

  &--link {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    background: transparent;
    color: var(--in-orange);
    transition: 0.2s ease-out;
    transition-property: transform;

    &:active {
      transform: scale(0.9);
    }

    &.disabled {
      color: #5e626a;
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  &[data-loading="true"] {
    opacity: 0.5;
    pointer-events: none;
  }

  &--greyscale {
    padding: 8px 12px;
    background: #ffffff;
    box-shadow: 0px 10px 25px rgba(151, 143, 175, 0.04);
    border-radius: 4px;
    color: #5e626a;
    font-size: 14px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    width: fit-content;
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &-text {
    font-size: 13px;
    color: inherit;
    @include mobile {
      display: none;
    }
  }
}

button:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.button--container {
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    padding: 12px 36px;
  }
}
