@mixin fadeLeft {
  animation-name: fadeLeft;
  animation-duration: 0.3s;
}

@mixin fadeRight {
  animation-name: fadeRight;
  animation-duration: 0.3s;
}

@mixin fadeTop {
  animation-name: fadeTop;
  animation-duration: 0.3s;
}

.fade-left {
  @include fadeLeft;
}

.fade-right {
  @include fadeRight;
}

.fade-top {
  @include fadeTop;
}

@keyframes fadeLeft {
  0% {
    transform: translate(50px, 0);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes fadeRight {
  0% {
    transform: translate(-50px, 0);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes fadeTop {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes topLineCross {
  0% {
    transform: translateY(-22px);
  }
  50% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(0px) rotate(45deg);
  }
}

@keyframes bottomLineCross {
  0% {
    transform: translateY(22px);
  }
  50% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(0px) rotate(-45deg);
  }
}

@keyframes topLineBurger {
  0% {
    transform: translateY(0px) rotate(45deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: translateY(-22px) rotate(0deg);
  }
}

@keyframes bottomLineBurger {
  0% {
    transform: translateY(0px) rotate(-45deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: translateY(22px) rotate(0deg);
  }
}
