.invoices-and-receipts-page {
  padding: 16px;
  overflow: visible;

  @media (min-width: 768px) {
    padding: 24px;
  }

  tr {
    td {
      padding-top: 12px;
      padding-bottom: 12px;

      @media (min-width: 768px) {
        padding-top: 16px;
        padding-bottom: 16px;
      }
    }

    td:last-of-type {
      width: auto;

      @media (min-width: 768px) {
        width: 105px;
      }

      > * {
        width: fit-content;
        margin-left: auto;
        display: block;
      }
    }
  }

  &__faint-text {
    color: var(--black2);
    font-weight: 300;
  }

  &__thick-text {
    font-weight: 500;
    font-size: 14px;

    @media (min-width: 768px) {
      font-size: 16px;
    }
  }

  .base-filter {
    .base-btn--primary {
      padding: 8px 16px;

      @media (min-width: 768px) {
        padding: 12px 24px;
      }
    }
  }
}

.invoices-and-receipts-tabs {
  .TabsList {
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 8px;

    @media (min-width: 768px) {
      flex-wrap: nowrap;
    }
  }

  .TabsTrigger {
    flex: 1 1 auto;
    white-space: nowrap;

    @media (min-width: 768px) {
      flex: 0 1 auto;
    }
  }
}

.invoice-preview {
  &__header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 10px;

    h4 {
      font-size: 16px;
      font-weight: 600;
    }

    button {
      font-size: 14px;
    }
  }

  &__description {
    margin-top: 8px;
    font-size: 14px;
    color: var(--black3);

    &__amount {
      font-weight: 500;
      font-size: 21px;
      line-height: 20px;
      letter-spacing: -0.4px;
      color: var(--black);
    }

    &.blockchain {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  &__select {
    order: 2;
    position: relative;

    select {
      appearance: none;
      width: 100%;
      // padding-right: 48px;
      color: var(--black3);

      &:invalid {
        color: var(--black3);
        font-weight: 300;
      }
    }

    &__arrow {
      position: absolute;
      top: 2px;
      left: 50px;
    }
  }

  &__information {
    padding: 24px 0;
    margin-top: 24px;
    border-top: 1px solid var(--gray);

    &__field {
      display: flex;
      margin-bottom: 8px;
      align-items: center;

      &__key {
        flex: 3;
        font-size: 14px;

        &.thick {
          font-size: 16px;
          font-weight: 500;
        }
      }
      &__value {
        flex: 1;
        flex-shrink: 0;
        font-size: 14px;

        &:nth-child(3) {
          color: var(--black3);
        }
      }
    }
  }

  &__notes {
    margin-top: 40px;

    h5 {
      font-weight: 300;
      font-size: 16px;
    }
    p {
      margin-top: 8px;
      font-size: 14px;
      letter-spacing: -0.4px;
      color: var(--black3);
      margin-bottom: 50px;
    }
  }

  &__items-table {
    thead {
      &::before {
        background-color: var(--black);
      }

      tr {
        td {
          color: #fff;
        }
      }
    }
    tbody {
      tr {
        td {
          color: var(--black3);
        }
      }
    }
  }

  &__footer {
    margin-left: auto;
    width: fit-content;
  }
}

.pay-invoice {
  &__heading {
    font-weight: 100;
    margin-bottom: 24px;
  }

  &__payment-method {
    display: flex;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid rgba(165, 173, 188, 0.2);
    margin-bottom: 24px;
    width: 100%;
    text-align: left;
    transition: 0.2s ease-out;

    &:hover {
      box-shadow: 0px 23px 44px rgba(176, 183, 195, 0.14);
      border: 1px solid var(--in-orange);
    }

    &__arrow {
      margin-left: auto;
    }

    &__icon {
      margin-right: 16px;
    }

    &__info {
      h3 {
        font-size: 16px;
        font-weight: 600;
      }

      h4 {
        font-size: 16px;
        font-weight: 500;
      }

      p {
        font-size: 14px;
        margin-top: 8px;
        max-width: 254px;
        line-height: 1.43;
        font-weight: 100;
      }
    }
  }

  .base-btn {
    font-size: 14px;
    margin-top: 24px;
  }
}

.create-invoice-page {
  padding: 40px;
  grid-template-columns: 198px 1fr;

  .invoice-preview {
    margin-bottom: 40px;
  }

  &__invoice-sent {
    text-align: center;

    h3 {
      font-size: 20px;
      margin: 14px 0 8px 0;
      font-weight: 500;
    }

    p {
      font-size: 14px;
      margin: 8px 0 24px 0;
      line-height: 1.71;
      color: var(--black2);
    }

    button {
      margin: 0 auto;
      font-size: 14px;
      padding: 12px 24px;
    }
  }

  &__form {
    .invoice-preview {
      max-width: 720px;
      border: 1px solid #edeff5;
      margin-top: 48px;
      padding: 40px;

      input {
        caret-color: var(--in-orange);
        background: transparent;

        &::placeholder {
          color: var(--black3);
        }

        &:focus {
          border-radius: 6px;
          // border: 2px solid var(--in-orange);
        }
      }

      &__description {
        input {
          width: 100%;
          max-width: 260px;
        }
      }

      &__information {
        &__field {
          &__value {
            max-width: 118px;
            flex: 2;
          }
        }
      }

      &__items-table {
        border-bottom: 1px solid #edeff5;
        margin-bottom: 10px;

        tbody,
        thead {
          tr {
            td {
              &:not(td:first-child) {
                max-width: 120px;
              }
              &:last-child {
                max-width: 80px !important;
                text-align: right;
                cursor: pointer;
              }
              input {
                width: 100%;
              }
            }
          }
        }
      }

      &__notes {
        textarea {
          width: 100%;
          resize: none;
          margin-top: 14px;
          border: 2px solid transparent;
          height: 100px;

          &:focus {
            border-radius: 6px;
            border: 2px solid var(--in-orange);
          }
        }
      }
    }

    &__heading {
      font-weight: 500;
      font-size: 20px;
    }

    &__description {
      margin: 8px 0 16px 0;
      color: var(--black3);
      line-height: 1.43;
      max-width: 440px;
    }

    &__note {
      font-size: 14px;
      color: var(--in-orange);
    }
  }
}

.pay-invoice-layout {
  min-height: 100vh;
  background-color: #f8f8f8;

  .auth-layout__header {
    &__links {
      padding: 12px;
      background: rgba(var(--in-orange-rgb), 0.1);
      border-radius: 8px;
      a {
        font-weight: 400;
        font-size: 16px;
      }
    }
  }

  .pay-invoice__no-auth {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;

    &__inner {
      background-color: var(--white);
      width: 500px;
      border-radius: 8px;

      h2,
      h4 {
        font-family: inherit;
        font-weight: 600;
      }
    }

    &__header {
      background-color: var(--black);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 40px;
      border-radius: 8px 8px 0 0;

      h2,
      h4,
      p {
        color: var(--white);
        padding-bottom: 8px;
      }
    }

    &__body {
      padding: 40px;

      h2 {
        font-size: 24px;
        line-height: 24px;
      }

      .line {
        margin: 16px 0 24px;
      }
    }
    button {
      font-size: inherit;
    }
  }
}
