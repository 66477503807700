.settings-page {
  padding: 24px;
  min-height: 100%;

  &__module-settings {
    margin: 40px 0 0 0;

    &__details {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 600px;
      padding-bottom: 16px;
      margin-bottom: 16px;
      border-bottom: 1px solid #e4e6eb;

      p {
        color: var(--black2);
      }

      select {
        appearance: none;
      }

      // .content-editable-container,
      // &__value {
      // margin-right: 8px;
      // min-width: 60px;
      // }

      &__value {
        opacity: 0.4;
      }

      span[contenteditable],
      select {
        padding: 2px 5px;
        border: 2px solid #dadada00;
        caret-color: var(--in-orange);
        font-size: 16px;
        text-align: right;
        font-weight: 300;

        &:focus {
          border: 2px solid rgba(var(--in-orange-rgb), 0.7);
          border-radius: 5px;
        }
      }

      div {
        display: flex;
        align-items: center;
      }
    }
  }

  &__payment-settings {
    margin: 40px 0 0 0;

    &__bank-accounts {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));

      grid-gap: 20px;

      &__create-btn {
        padding: 24px;
        background: rgba(var(--in-orange-rgb), 0.04);
        border: 1px solid rgba(var(--in-orange-rgb), 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 12px;
        transition: 0.2s ease;

        &:hover {
          box-shadow: 0px 23px 44px rgba(176, 183, 195, 0.14);
        }

        p {
          color: var(--in-orange);
          margin-top: 24px;
        }
      }
    }

    &__bank-account {
      border: 1px solid #e4e6eb;
      background: #fff;
      padding: 24px;
      border-radius: 16px;
      transition: 0.2s ease;

      &:hover {
        box-shadow: 0px 23px 44px rgba(176, 183, 195, 0.14);
      }

      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__icon {
          border: 5px solid rgba(210, 206, 206, 0.4);
          display: flex;
          border-radius: 100vh;
          overflow: hidden;
        }
      }

      > p {
        margin: 28px 0 14px 0;
        font-weight: 500;
      }

      &__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          font-size: 14px;
          color: var(--black2);
        }
      }
    }
  }

  &__user-role-settings {
    .base-table {
      tr {
        td {
          padding-top: 16px;
          padding-bottom: 16px;
        }
      }
    }

    &__header-btn {
      margin-left: auto;
      font-size: 14px;
      padding: 12px 24px;
      margin-bottom: 20px;
      margin-top: 10px;
    }

    &__name {
      display: flex;
      align-items: center;
      --green2-rgb: 21, 163, 54;

      span {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--sky-blue);
        color: #fff;
        font-weight: 500;
        border-radius: 100vh;
        font-size: 12px;
        margin-right: 16px;
      }
    }

    &__actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      button {
        margin-left: 26px;
      }
    }

    &__new-user {
      &__heading {
        margin-top: 24px;
        h3 {
          font-size: 16px;
          font-weight: 500;
        }
        p {
          margin: 4px 0 24px 0;
          font-size: 14px;
          color: var(--black2);
        }
      }

      &__radios {
        margin-bottom: 24px;
        border-bottom: 1px solid #e4e6eb;
      }

      &__radio {
        margin-bottom: 24px;
        display: block;
        cursor: pointer;
        position: relative;

        input {
          position: absolute;
          top: 0;
          opacity: 0;

          &:checked + div {
            background: #fff;
            border: 1px solid rgba(var(--in-orange-rgb), 0.31);

            &:after {
              border: 4px solid var(--in-orange);
            }
          }
        }

        > div {
          background: #fbfbfb;
          display: block;
          padding: 16px;
          padding-right: 48px;
          position: relative;
          border-radius: 8px;
          border: 1px solid transparent;
          transition: 0.2s ease-out;

          &:after {
            content: "";
            height: 16px;
            width: 16px;
            border: 1px solid #ced4da;
            background: #fff;
            display: block;
            border-radius: 100vh;
            position: absolute;
            right: 16px;
            top: 50%;
            box-sizing: border-box;
            transform: translateY(-50%);
            transition: 0.2s ease-out;
          }
        }

        h3 {
          font-size: 16px;
          font-weight: 400;
          letter-spacing: -0.005em;
          margin-bottom: 4px;
        }
        p {
          font-size: 14px;
          line-height: 1.43;
          color: var(--black2);
        }
      }

      .base-btn--primary {
        padding: 12px 24px;
      }
    }

    &__invite-sent {
      text-align: center;

      h1 {
        font-size: 20px;
        margin-top: 24px;
        font-weight: 600;
      }

      div {
        max-width: 300px;
        margin: 8px auto 24px auto;
        font-size: 14px;
        line-height: 1.71;
        color: var(--black2);
      }

      button {
        margin: 0 auto;
        font-size: 14px;
        padding: 12px 24px;
      }
    }
  }

  &__security-settings {
    padding: 40px 0;

    &__2fa-btn {
      display: flex;
      align-items: flex-start;
      border: 1px solid #e4e6eb;
      border-radius: 8px;
      padding: 16px;
      text-align: left;
      background: transparent;
      font-weight: 300;
      margin-bottom: 24px;
      transition: 0.2s ease;

      &:hover {
        box-shadow: 0px 13px 14px rgba(176, 183, 195, 0.14);
      }

      // &:first-of-type {
      //   margin-top: 64px;
      // }

      &__details {
        margin: 0 16px;
        h3 {
          font-weight: 400;
          font-size: 16px;
        }

        p {
          font-size: 14px;
          max-width: 248px;
          margin-top: 8px;
          color: var(--black2);
          line-height: 1.29;
        }
      }
    }

    &__group {
      display: flex;
      align-items: start;
      margin-bottom: 56px;

      &__details {
        flex: 1;
        flex-shrink: 0;
        max-width: 300px;
        margin-right: 80px;

        h1 {
          font-family: "Grillmaster ExtWide";
          color: var(--oxford-blue);
          font-weight: 400;
          font-size: 21px;
        }

        p {
          font-size: 14px;
          color: var(--black2);
          line-height: 1.43;
          margin-top: 8px;
        }
      }

      &__form {
        flex: 1;
        flex-shrink: 0;
        max-width: 360px;
        padding: 24px;

        .base-btn--primary {
          padding: 16px 24px;
          margin-top: 24px;
        }
      }
    }
  }
}

.add-new-bank-account {
  .base-btn--primary {
    margin-top: 24px;
  }
}

.authenticator-app {
  &__details {
    ol {
      counter-reset: step;
      list-style: none;
      padding: 0;
      margin: 0;
      margin-bottom: 24px;

      li {
        counter-increment: step;
        position: relative;
        padding-left: 32px;
        margin-bottom: 16px;

        &:before {
          content: counter(step);
          position: absolute;
          left: 0;
          top: 0;
          width: 24px;
          height: 24px;
          border-radius: 100vh;
          background: var(--oxford-blue);
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          font-weight: 500;
        }
      }
    }
  }
  .button-container {
    button {
      width: 158px;
      height: 52px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;

      &:first-of-type {
        background-image: url("/assets/images/download-playstore-icon.svg");
      }

      &:last-of-type {
        background-image: url("/assets/images/download-appstore-icon.svg");
      }
    }
  }
}
