.create-team {
  button {
    margin-top: 24px;
  }
}

.teams-page {
  padding: 40px 24px !important;

  &__manage-teams {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

    &__create-team,
    &__team {
      transition: 0.4s ease-out;

      &:hover {
        box-shadow: 0px 23px 34px rgba(176, 183, 195, 0.3);
      }
    }

    &__create-team {
      padding: 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 1px solid rgba(var(--in-orange-rgb), 0.3);
      background: rgba(var(--in-orange-rgb), 0.04);
      border-radius: 12px;

      span {
        margin-top: 24px;
        color: var(--in-orange);
      }
    }

    &__team {
      padding: 29px 24px;
      @extend .flex-col !optional;
      @extend .justify-between !optional;

      p {
        font-style: normal;
        font-weight: 300;
        color: var(--black2);
        margin: 4px 0 29px 0;
        font-size: 14px;
      }

      &__heading {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h3 {
          font-size: 18px;
          font-family: "Grillmaster ExtWide";
          font-weight: 500;
        }
      }

      &__members {
        display: flex;
        align-items: center;

        p {
          margin-bottom: 0;
          margin-left: 10px;
        }
      }

      &__member {
        width: 40px;
        height: 40px;
        background: var(--in-orange);
        border-radius: 100vh;
        border: 1.5px solid #ffffff;
        margin-left: -8px;
        overflow: hidden;

        &:last-of-type {
          display: flex;
          align-items: center;
          justify-content: center;
          background: linear-gradient(0deg, #3f3d56 0%, #434175 100%);
          font-size: 10px;
          color: #fff;
        }

        @for $i from 1 through 5 {
          &:nth-child(#{$i}) {
            position: relative;
            z-index: 5 - $i;
          }
        }
      }
    }
  }
}
