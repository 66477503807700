.payroll-run-page {
  padding: 24px;
  overflow: visible;

  &__run-btn {
    margin-left: auto;
    font-size: 14px;
    padding: 12px 24px !important;
  }

  .base-filter {
    margin-top: 0;
  }

  &__thick-text {
    font-size: 16px;
    font-weight: 400;
  }

  &__table {
    td:last-child {
      button {
        margin-left: auto;
        display: block;
      }
    }
  }
}

.payroll-confirm-payment-page {
  &__stats {
    display: flex;

    &__stat-card {
      flex: 1;
      flex-shrink: 0;
      padding: 0 40px;
      margin: 40px 0;
      border-right: 1px solid #e4e6eb;

      h3 {
        font-family: "Grillmaster ExtWide";
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 4px;
      }

      &:first-child {
        h3 {
          font-size: 24px;
        }
      }

      &:last-child {
        border-right: 0;
      }

      p {
        font-size: 16px;
        color: var(--grey);
      }
    }
  }

  &__stats-actions {
    padding: 24px;
    display: flex;
    gap: 8px;
    background: #fbfbfb;

    .base-btn {
      font-size: 14px;

      &--primary {
        padding: 12px 14px;
      }
      &--alternate {
        padding: 12px 29px;
      }
    }
  }

  &__table-container {
    margin: 40px 0;
    padding: 24px;
  }

  &__total-amount {
    padding: 17px 0;
    border-top: 1px solid #edeff5;
    text-align: right;

    h3 {
      font-size: 20px;
      font-family: "Grillmaster ExtWide";
    }
    p {
      margin-top: 4px;
      color: var(--grey);
    }
  }

  &__status {
    text-align: center;

    h3 {
      font-size: 20px;
      font-weight: 600;
      margin: 24px 0 0 0;
    }

    p {
      font-size: 14px;
      line-height: 1.71;
      max-width: 300px;
      margin: 8px auto 24px auto;
      color: var(--grey);
    }

    button {
      margin: 0 auto;
      font-size: 14px;
      padding: 12px 24px;
    }
  }
}

.payroll-settings-page {
  padding: 40px;

  h3 {
    font-weight: 600;
    font-size: 24px;
  }

  .settings-page__module-settings {
    margin-top: 25px;
  }

  &__pay-period-settings {
    .base-btn {
      padding: 12px 24px;
      font-size: 14px;
    }

    &__friday-payment {
      margin-bottom: 24px;

      p {
        font-weight: 400;
      }

      &__info {
        display: flex;
        align-items: flex-start;
        gap: 24px;
        margin-top: 8px;

        p {
          color: var(--grey);
          line-height: 1.38;
          font-size: 14px;
          font-weight: 300;
        }

        .base-switch {
          flex-shrink: 0;
        }
      }
    }
  }
}

.payroll-history-page {
  &__history-card {
    background-color: #fff;
    border: 1px solid #f0f2f7;
    border-radius: 12px;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 24px;

    @media (min-width: 768px) {
      padding: 24px 40px;
      flex-wrap: nowrap;
      align-items: center;
    }

    &__stat {
      flex: 1 1 50%;
      margin-bottom: 16px;
      padding-right: 16px;

      @media (min-width: 768px) {
        flex: 1;
        margin-bottom: 0;
        border-right: 1px solid #e4e6eb;
        margin-right: 20px;
        padding-right: 20px;
      }

      @media (min-width: 1024px) {
        margin-right: 40px;
      }

      h3 {
        font-family: var(--primary-font);
        font-size: 18px;

        @media (min-width: 768px) {
          font-size: 20px;
        }
      }

      p {
        margin: 4px 0 0 0;
        color: var(--black2);
      }

      &:not(:first-child) {
        h3 {
          font-size: 16px;
        }
      }

      &:last-of-type {
        border-right: none;
      }
    }

    &__more-btn {
      flex: 1 1 100%;
      margin-top: 16px;

      @media (min-width: 768px) {
        flex: 0 0 auto;
        margin-top: 0;
        margin-left: 20px;
      }

      @media (min-width: 1024px) {
        margin-left: 40px;
      }

      .base-btn--clear {
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (min-width: 768px) {
          justify-content: flex-start;
        }
      }
    }
  }
}
