.auth-layout {
  max-width: 1200px;
  padding: 40px 20px;
  margin-right: auto;
  margin-left: auto;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .base-tabs {
      &__tab {
        padding: 8px 25px;
      }
    }
  }
}
