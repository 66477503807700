@import "./mixins";

.base-modal {
  position: fixed;
  width: 100%;
  background: #fff;
  border-radius: 8px;
  padding: 24px;
  padding-bottom: 40px;
  overflow: auto;
  z-index: 101;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

  &--default {
    height: calc(100% - 48px);

    max-width: 450px;
    top: 0;
    right: 0;
    margin: 24px;
    animation: fadeIn 0.3s forwards;

    @include mobile {
      margin: 0;
      height: 100%;
      border-radius: 0;
      padding: 24px;
      max-width: 100% !important;
    }
  }

  &--pop-up {
    left: 50%;
    top: 100px;
    transform: translate(-50%, 0);
    max-width: 380px;
    height: unset;
    max-height: calc(100vh - 200px);
    animation: fadeInPopUp 0.3s forwards;

    @include mobile {
      top: 0;
      left: 50%;
      right: 0;
      transform: translate(0, 0);
      max-width: 100%;
      max-height: 100%;
      height: 100%;
      border-radius: 0;
      padding: 24px;
    }
  }

  &--full-screen {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
    padding: 24px;
    animation: slideIn 0.3s forwards;

    @include mobile {
      padding: 24px;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 16px;
    margin-bottom: 24px;
    border-bottom: 1px solid #eeeeee;

    h3 {
      font-size: 24px;
      font-weight: 600;
    }
  }

  @keyframes fadeIn {
    from {
      transform: translateY(50px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes fadeInPopUp {
    from {
      transform: translate(-50%, 50px) scale(0.6);
      opacity: 0;
    }
    to {
      transform: translate(-50%, 0) scale(1);
      opacity: 1;
    }
  }

  @keyframes slideIn {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }

  &__payment-status {
    text-align: center;
    margin: 60px 0;

    h4 {
      margin: 24px 0 8px 0;
      font-weight: 500;
      font-size: 20px;
    }

    p {
      max-width: 300px;
      margin: 0 auto 24px auto;
      font-size: 14px;
      line-height: 1.71;
    }

    .base-btn {
      padding: 16px 61px;
      font-size: 14px;
      margin: 0 auto;
    }
  }
}

.base-modal--overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(17, 17, 17, 0.4);
  z-index: 100;
  opacity: 0;
  animation: overlayFadeIn 0.3s forwards;
}

@keyframes overlayFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.base-btn--clear {
  background: none;
  border: none;
  cursor: pointer;
}
