.talents-bulk-upload {
  &__description {
    line-height: 1.5;
    max-width: 343px;
    margin-bottom: 24px;
    color: var(--black2);
  }

  .base-btn--link {
    margin-bottom: 24px;
  }

  .base-btn--primary {
    margin-top: 24px;
  }
}

.talents-page {
  > .base-card {
    padding: 24px;
  }
}

.create-talent {
  .base-btn--primary {
    margin-top: 24px;
  }
}
