@import "./common/mixins";

.onboarding-layout {
  @include desktop {
    display: flex;
    justify-content: space-between;
  }
  min-height: 100vh;

  &__story {
    flex: 1;
    max-width: 487px;
    border: 8px solid #fff;
    background: var(--oxford-blue);
    display: flex;
    align-items: center;
    max-height: 100vh;
    position: sticky;
    top: 0;

    &__text {
      text-align: center;
      max-width: 305px;
      margin: 0 auto;

      &,
      * {
        color: #fff;
      }

      h4 {
        font-family: "Grillmaster ExtWide";
        font-size: 20px;
        font-weight: 700;
        margin: 56px 0 8px 0;
      }

      p {
        line-height: 1.5;
        margin-bottom: 56px;
      }

      &__story-slider-control {
        display: flex;
        width: 200px;
        margin: 0 auto;

        span {
          flex: 1;
          display: block;
          height: 3px;
          margin: 0 2px;
          background: #fff;
          border-radius: 100vh;

          &.active {
            background: var(--in-orange);
          }
        }
      }
    }

    @include mobile {
      display: none;
    }
  }

  &__form-holder {
    flex: 1;
    padding: 0 1.5rem 40px;
  }
}

// Business and talent form
.onboarding-form {
  max-width: 360px;
  margin: 103px auto 40px auto;

  &-holder {
    overflow: auto;
  }

  &__logo {
    text-align: center;
  }

  &__tabs {
    margin-bottom: 24px;
  }

  &__heading {
    text-align: center;
    font-family: "Grillmaster ExtWide";
    font-size: 22px;
    line-height: 32px;
    font-weight: 700;
    margin-top: 40px;
    margin-bottom: 24px;
  }

  &__description {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: var(--black2);
  }

  &__inputs {
    @include fadeLeft;
  }

  .base-btn--primary {
    width: 100%;
    font-weight: 500;
    margin-top: 40px;
    letter-spacing: -0.4px;
  }
}

.onboarding-form-footer {
  text-align: center;
  width: fit-content;
  margin: 0 auto;
}

.password-checker {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;

  p {
    font-size: 14px;
    font-weight: 500;
  }

  &__criteria {
    display: flex;
    align-items: center;
    gap: 8px;

    &__status {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      font-size: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.default {
        background-color: #d9d9d9;
        color: #777e90;
      }

      &.success {
        background-color: #0fbd70;
        color: #0fbd70;
      }

      &.error {
        background-color: #c5292a;
        color: #c5292a;
      }
    }

    svg {
      fill: #fff;
    }

    p {
      font-weight: 500;
      &.default {
        color: #777e90;
      }

      &.success {
        color: #0fbd70;
      }

      &.error {
        color: #c5292a;
      }
    }
  }
}
