.notification {
  background: #fff !important;
  box-shadow: 0 0 60px #c8c1c1 !important;
  color: var(--oxford-blue) !important;
  padding: 15px 15px 15px 52px !important;
  border-left: 3px solid;
  opacity: 1 !important;

  .title {
    font-size: 14px !important;
    font-weight: 400 !important;
  }

  .message {
    font-size: 14px !important;
    color: var(--black2);

    &::first-letter {
      text-transform: capitalize;
    }
  }

  &-success {
    border-color: var(--green2);

    &::before {
      color: var(--green2);
    }
  }

  &-error {
    border-color: var(--red);

    &::before {
      color: var(--red);
    }
  }
}

.notification-container {
  overflow: visible !important;
}
