.skeleton-loader {
  &__line {
    background: linear-gradient(
      90deg,
      rgba(207, 216, 220, 0.2),
      rgba(207, 216, 220, 0.4),
      rgba(207, 216, 220, 0.2)
    );
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
    height: 26px;
    margin-bottom: 16px;
    border-radius: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
