.login-user {
  &__form {
    margin-top: 86px;
    max-width: 360px;
    margin-right: auto;
    margin-left: auto;

    h3 {
      font-size: 24px;
      font-family: "Grillmaster ExtWide";
      text-align: center;
      margin-bottom: 24px;
    }

    &__forgot-password {
      border-bottom: 1px solid var(--in-orange);
      margin-left: auto;
      display: block;
      width: fit-content;
    }

    &__login-button {
      width: 100%;
      margin-top: 40px;
    }
  }
  &__no-account-yet {
    text-align: center;
    margin-top: 30px;

    a {
      border-bottom: 1px solid var(--in-orange);
    }
  }
}
