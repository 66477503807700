@font-face {
    font-family: 'Euclid Circular A';
    src: url('EuclidCircularA-BoldItalic.woff2') format('woff2'),
        url('EuclidCircularA-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Circular A';
    src: url('EuclidCircularA-Bold.woff2') format('woff2'),
        url('EuclidCircularA-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Circular A';
    src: url('EuclidCircularA-Italic.woff2') format('woff2'),
        url('EuclidCircularA-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Circular A';
    src: url('EuclidCircularA-LightItalic.woff2') format('woff2'),
        url('EuclidCircularA-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Circular A';
    src: url('EuclidCircularA-Light.woff2') format('woff2'),
        url('EuclidCircularA-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Circular A';
    src: url('EuclidCircularA-MediumItalic.woff2') format('woff2'),
        url('EuclidCircularA-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Circular A';
    src: url('EuclidCircularA-SemiBoldItalic.woff2') format('woff2'),
        url('EuclidCircularA-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Circular A';
    src: url('EuclidCircularA-Regular.woff2') format('woff2'),
        url('EuclidCircularA-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Circular A';
    src: url('EuclidCircularA-Medium.woff2') format('woff2'),
        url('EuclidCircularA-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Circular A';
    src: url('EuclidCircularA-SemiBold.woff2') format('woff2'),
        url('EuclidCircularA-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

