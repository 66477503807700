@import "./tailwind.scss";

@import "./common/animations";
@import "./common/font";
@import "./common/mixins";
@import "./common/tab";
@import "./common/input";
@import "./common/btn";
@import "./common/pagination";
@import "./common/notification";
@import "./common/card";
@import "./common/tag";
@import "./common/pageLoader";
@import "./common/modal";
@import "./common/graph";
@import "./common/filter";
@import "./common/dropDown";
@import "./common/table";
@import "./common/qrCode";
@import "./common/typography";

@import "./error.module.scss";
@import "./onboard.module.scss";
@import "./onboardLayout.module.scss";
@import "./authLayout.module.scss";
@import "./inviteLayout.module.scss";
@import "./loginUser.module.scss";
@import "./forgotPassword.module.scss";
@import "./sideNavigation.module.scss";
@import "./dashboard.module.scss";
@import "./setup.module.scss";
@import "./team.module.scss";
@import "./talents.module.scss";
@import "./wallet.module.scss";
@import "./settings.module.scss";
@import "./skeleton.module.scss";
@import "./invoicesAndReceipts.module.scss";
@import "./payroll.module.scss";
@import "./compliance.module.scss";
@import "./contracts.module.scss";
@import "./common.scss";
@import "./baseTable.module.scss";

.app-container {
  @include desktop {
    display: grid;
    grid-template-columns: 260px 1fr;
  }
  min-height: 100vh;
  background: #fbfbfd;

  &__main-content {
    padding: 40px;
    max-width: 1400px;
    margin: 0 auto;
    @include phone {
      padding: 40px 1.5rem;
    }
  }
}
