.contract-page {
  padding: 0 24px;

  &__faint-text {
    color: var(--black2);
  }

  &__thick-text {
    font-weight: 500;
    font-size: 16px;
  }

  &__link {
    text-decoration: underline;
    color: var(--red);
    background: none;
  }

  &__contract-details {
    &__heading {
      font-weight: 600;
      font-size: 16px;
    }

    &__sub-heading {
      margin: 8px 0 0 0;
      font-size: 14px;
    }

    &__info {
      border: 1px solid #eef1f8;
      box-shadow: 0px 6px 32px rgba(0, 0, 0, 0.05);
      border-radius: 16px;
      padding: 24px;
      margin: 24px 0;
      max-height: 60vh;
      line-height: 1.43;
      overflow: auto;
    }
  }
}
