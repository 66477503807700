.onboard-page {
  padding: 40px 80px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__heading {
    font-family: "Grillmaster ExtWide";
    font-weight: 700;
    font-size: 24px;
    line-height: 1.33;
    text-align: center;
    margin-top: 166px;
    margin-bottom: 64px;
    @include fadeTop;
  }

  &__radio-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 24px;
    width: 100%;
    @include fadeTop;

    &__radio {
      border: 1.5px solid #e6e8ec;
      border-radius: 16px;
      padding: 40px 32px;
      cursor: pointer;
      min-width: 248px;
      width: 348px;
      transition: 0.1s ease;
      background: none;
      text-align: left;

      &:active {
        transform: scale(0.95);
      }

      &:hover {
        border-color: rgba(var(--in-orange-rgb), 1);
        background: rgba(var(--in-orange-rgb), 0.03);
      }

      h4 {
        font-size: 18px;
        margin: 27px 0 16px 0;
        font-weight: 600;
        color: var(--oxford-blue);
      }

      p {
        font-size: 14px;
        color: var(--black2);
      }
    }
  }
}
