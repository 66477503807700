@import "./mixins";

.base-drop-down {
  border: 1px solid #e2e4e8;
  padding: 8px 13px;
  border-radius: 4px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  color: var(--black2);
  font-size: 15px;
  position: relative;

  &-container {
    position: relative;
    overflow: visible;
    padding: 10px 0;
  }

  &__overlay {
    height: 100%;
    width: 100%;
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    opacity: 0;
    pointer-events: none;

    &[data-is-open="true"] {
      opacity: 1;
      pointer-events: unset;
    }
  }

  &__item {
    position: absolute;
    right: 0;
    top: 100%;
    background: #fff;
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding-top: 12px;
    z-index: 11;
    opacity: 0;
    pointer-events: none;
    transform: translate(0, -10px);
    transition: 0.2s ease;

    &[data-is-open="true"] {
      opacity: 1;
      pointer-events: unset;
      transform: translate(0, 0px);
    }

    .base-btn--clear {
      display: flex;
      align-items: center;
      font-size: 14px;
      padding: 0 14px;
      margin-bottom: 12px;
      white-space: nowrap;
      color: var(--grey);

      img {
        max-width: unset !important;
      }

      > * {
        flex-shrink: 0;
      }
    }
  }

  &__prefix-icon {
    display: flex;
    width: 30px;
    @include desktop {
      margin-right: 12px;
    }
  }

  &__arrow {
    @include desktop {
      margin-left: 32px;
    }
    display: flex;
  }

  @include mobile {
    .text {
      display: none;
    }
  }
}
