.base_table_wrapper {
  width: 100%;
  max-height: 600px;
  overflow: auto;

  table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;

    thead {
      position: sticky;
      top: -1px;
      background-color: #f6f7f9;
      z-index: 1;

      th {
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 0.6px;
        font-weight: 500;
        padding: 10px 16px;
        text-align: left;
        color: var(--black2);
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid #edeff5;

        &:last-child {
          border-bottom: none;
        }

        &:hover {
          background: #fff;
          border-radius: 8px;
          box-shadow: 0px 0px 40px rgba(176, 183, 195, 0.315);
        }

        td {
          font-size: 14px;
          padding: 10px 16px;
          font-weight: 400;
          color: var(--black2);
        }

        .table_dropdown {
          width: 40px;

          &_wrapper {
            overflow: visible;
            position: relative;

            .dropdown_btn {
              width: 40px;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: transparent;
            }

            .dropdown {
              overflow: visible;
              border: 1px solid #f4f4f4;
              background-color: #fff;
              padding: 6px;
              position: fixed;
              z-index: 1000;
              border-radius: 8px;
              width: fit-content;
              min-width: 180px;
              display: flex;
              flex-direction: column;
              gap: 6;
              box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);

              button {
                white-space: nowrap;
                font-size: 14px;
                text-align: left;
                background-color: transparent;
                height: 36px;
                padding: 0 8px;
                border-radius: 8px;
                display: flex;
                align-items: center;
                gap: 8px;

                &:hover {
                  background-color: #f4f4f4;
                }
              }
            }
          }
        }
      }
    }
  }
}
