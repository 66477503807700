@import "./common/mixins";

.error-message-wrapper {
  padding: 40px 0 24px;

  h3 {
    font-weight: 700;
    margin-top: 4px;
    text-transform: capitalize;
  }

  main {
    display: flex;
  }
}

.custom-error {
  height: 100vh;
  padding: 16px 16px 20px;

  @include mobile {
    padding-left: 16px;
    padding-right: 16px;
  }

  &.modal {
    height: 100%;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 81px;
    padding: 0 16px;
  }

  main {
    max-width: 980px;
    margin: 0 auto;
  }

  &__inner-container {
    position: relative;
    text-align: center;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    a {
      border-bottom: 1px solid var(--in-orange);
    }
  }
}
