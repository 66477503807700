.forgot-password {
  &__form {
    margin-top: 86px;
    max-width: 360px;
    margin-right: auto;
    margin-left: auto;

    h3 {
      font-size: 24px;
      font-family: "Grillmaster ExtWide";
      text-align: center;
      margin-bottom: 24px;
    }

    p {
      line-height: 1.5;
      font-size: 14px;
      margin-bottom: 24px;
      color: var(--black2);

      strong {
        font-weight: 500;
        color: var(--black2);
      }
    }

    .base-btn--primary {
      width: 100%;
      margin-top: 40px;
    }
  }

  &__not-your-account {
    text-align: center;
    margin-top: 40px;
    font-size: 16px !important;

    button {
      font-size: inherit;
      text-decoration: underline;
    }
  }
}
