.qr-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 1.5rem 0;
}

.qr-code-left {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;

  &::before,
  &::after {
    border-color: #d6d2fd;
    border-width: 2px;
    content: "";
    display: block;
    height: 24px;
    pointer-events: none;
    position: absolute;
    width: 24px;
    border-top-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
  }

  &::after {
    top: 0;
    border-top-left-radius: 12px;
    border-right: none;
    border-bottom: none;
  }

  &::before {
    bottom: 0;
    border-bottom-left-radius: 12px;
    border-right: none;
    border-top: none;
  }
}

.qr-code-right {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;

  &::before,
  &::after {
    border-color: #d6d2fd;
    border-width: 2px;
    content: "";
    display: block;
    height: 24px;
    pointer-events: none;
    position: absolute;
    width: 24px;
    border-right-style: solid;
    right: 0;
  }

  &::after {
    top: 0;
    border-top-right-radius: 12px;
    border-left: none;
    border-bottom: none;
    border-top-style: solid;
  }

  &::before {
    bottom: 0;
    border-bottom-right-radius: 12px;
    border-left: none;
    border-top: none;
    border-bottom-style: solid;
  }
}
