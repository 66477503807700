@import "./common/mixins";

.dashboard-page {
  &__greeting {
    font-size: 20px;
    font-weight: 400;

    strong {
      font-family: "Grillmaster ExtWide";
      line-height: 1.5;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-gap: 24px;
    padding: 40px 0;
    @include phone {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .dashboard-page__recent-transactions {
      margin-top: 0;
    }
  }

  &__start-text {
    color: var(--black3);
    line-height: 1.5;
  }

  &__payments {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    grid-gap: 24px;
    padding: 40px 0;

    &__card--sm {
      max-width: 276px;
      flex: 1;
    }
  }

  &__days-to-payroll {
    background: #fff1eb;
    border: 1px solid rgba(254, 80, 0, 0.12);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 24px;
    position: relative;

    &__count {
      color: var(--in-orange);
      font-family: var(--primary-font);
    }

    &__title {
      margin-bottom: 4px;
      line-height: 2;
      color: var(--oxford-blue);
      font-family: var(--primary-font);
      font-weight: 400;
    }

    &__description {
      font-size: 14px;
      line-height: 1.6;
      max-width: 200px;
      color: #817f7f;
    }

    &__img {
      position: absolute;
      right: 0;
      bottom: 0;
      transform: translate(10px, 15px);
    }
  }

  &__countdown {
    @extend .base-card;
    background: #fff1eb;
    border: 1px solid rgba(var(--in-orange-rgb) 0.12);
    border-radius: 12px;
    padding: 24px;
    display: flex;
    align-items: center;
    margin-top: 40px;

    > span {
      margin-right: 24px !important;
    }

    p {
      margin-top: 8px;
    }
  }

  &__payment {
    min-height: 181px;
    display: flex;
    align-items: center;
    flex: 1;
    flex-shrink: 0;

    &__balance {
      font-family: "Grillmaster ExtWide";
      margin-bottom: 8px;
      display: flex;

      span {
        margin-left: 16px;
      }
    }

    p {
      color: var(--black2);
    }

    &__info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 20px 24px;
      flex: 1;

      &:nth-child(even) {
        border-left: 1px solid #e4e6eb;
      }

      .base-btn {
        padding: 16px 29px;
        margin-top: 29px;
        width: fit-content;
      }
    }
  }

  &__analytics {
    padding: 32px 40px;

    @include mobile {
      padding: 32px 24px;
    }

    &__header {
      padding-bottom: 18px;
      margin-bottom: 32px;
      border-bottom: 1px solid #e4e6eb;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .base-input {
        input {
          padding: 8px 14px;
          color: var(--grey);
          background: #f5f6f8;
        }
      }

      h2 {
        font-family: "Grillmaster ExtWide";
        font-size: 20px;
      }
    }
  }

  &__recent-transactions {
    margin-top: 40px;
    padding: 32px 40px;

    &__header {
      padding-bottom: 18px;
      margin-bottom: 32px;
      border-bottom: 1px solid #e4e6eb;
      display: flex;
      align-items: center;
      justify-content: space-between;

      h2 {
        font-family: "Grillmaster ExtWide";
        font-size: 20px;
      }

      .base-btn--link {
        text-decoration: underline;
      }
    }

    &__table {
      width: 100%;

      td {
        padding-bottom: 40px;
      }
    }
  }

  &__recent-transaction {
    td:last-of-type {
      > div {
        margin-left: auto;
        width: fit-content;
      }
    }

    &__data {
      display: flex;
      align-items: center;

      .base-tag--success {
        border-radius: 4px;
        padding: 4px 10px;
      }
    }

    &__icon {
      margin-right: 16px;
    }

    &__text {
      h4 {
        font-weight: 400;
      }
      p {
        font-size: 14px;
        margin-top: 4px;
        color: var(--black3);
      }
    }

    &__amount {
      font-weight: 500;
    }
  }
}

.dashboard-page__chart {
  height: 100%;
  width: 100%;
  position: absolute;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &-wrapper {
    height: 230px;
    width: 230px;
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  &-label {
    p,
    h4 {
      position: relative;
      z-index: 1;
      display: block;
      text-align: center;
    }

    p {
      font-size: 16px;
      color: var(--black3);
    }

    h4 {
      font-size: 24px;
      font-weight: 500;
      margin-top: 4px;
    }
  }

  &__data-labels {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-gap: 20px;
    width: 100%;
    margin-top: 30px;
    justify-items: start;
  }

  &__data-legend {
    color: var(--black3);
    display: flex;
    align-items: center;
    justify-content: center;

    &__circle {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 8px;
    }

    &__text {
      font-size: 14px;
    }
  }

  &-container {
    padding: 32px 40px;
  }

  &:after {
    content: "";
    height: 70%;
    width: 70%;
    background: #fff;
    top: 0;
    left: 0;
    border-radius: inherit;
  }

  &__header {
    padding-bottom: 18px;
    margin-bottom: 32px;
    border-bottom: 1px solid #e4e6eb;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      font-family: "Grillmaster ExtWide";
      font-size: 20px;
    }

    .base-btn--link {
      text-decoration: underline;
    }
  }
}

.dashboard-chart-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
}

.dashboard-chart-date-filter {
  position: relative;
  width: 180px;

  .dashboard-chart-date-filter-btn {
    width: 100%;
    height: 36px;
    border-radius: 8px;
    border: 1px solid #e5e7eb;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 10px;
    font-size: 14px;
    background-color: #ffffff;

    span {
      text-transform: capitalize;
    }

    svg {
      font-size: 20px;
    }
  }

  .dashboard-chart-date-filter-dropdown {
    position: absolute;
    padding: 6px 0 0 0;
    width: 100%;
    z-index: 10;

    div {
      width: 100%;
      padding: 6px;
      border-radius: 8px;
      border: 1px solid #e5e7eb;
      background-color: #ffffff;
      box-shadow: 0 3px 10px rgb(0 0 0 / 0.1);
      display: flex;
      flex-direction: column;
      gap: 4px;

      button {
        width: 100%;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px;
        background-color: #ffffff;
        border-radius: 6px;

        &:hover {
          background-color: hsl(220 14.3% 95.9%);
        }

        &.active {
          background-color: hsl(220 14.3% 95.9%);
        }

        svg {
          font-size: 20px;
        }
      }
    }
  }
}

.dashboard-chart-legend {
  list-style-type: "none";
  margin: 27px 0 0 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 24px;
  row-gap: 12px;

  li {
    margin-right: 10px;
    display: flex;
    align-items: center;
    gap: 8px;

    div {
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }
    span {
      font-size: 14px;
      color: #130e00;
      white-space: nowrap;
    }
  }
}

.talent-chart-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__header {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;

    p {
      display: flex;
      align-items: center;
      gap: 4px;

      span:first-child {
        font-size: 16px;
        color: #424755;
      }

      span:last-child {
        font-size: 18px;
        font-weight: 500;
        color: #424755;
      }
    }
  }
}
