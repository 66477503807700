.page-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;

  svg {
    height: 80px;
    width: 80px;
  }

  &__track {
    width: 300px;
    height: 4px;
    background: rgba(var(--in-orange-rgb), 0.2);
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
  }

  &__thumb {
    width: 40px;
    height: 4px;
    background: rgba(var(--in-orange-rgb), 1);
    animation-name: load;
    animation-duration: 1s;
    animation-iteration-count: infinite;

    @keyframes load {
      0% {
        transform: translate(0, 0);
      }
      50% {
        transform: translate(260px, 0);
      }
    }
  }
}

.spinner-border {
  --current-color: var(--in-orange);
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid var(--current-color);
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;

  &-primary {
    --current-color: var(--in-orange);
  }

  &-light {
    --current-color: var(--white);
  }

  @keyframes spinner-border {
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  &-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
  }

  &-md {
    width: 2rem;
    height: 2rem;
    border-width: 0.25em;
  }

  &-lg {
    width: 3rem;
    height: 3rem;
    border-width: 0.3em;
  }
}
