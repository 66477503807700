@import "./mixins";

.table-container {
  width: 100%;
  max-height: 600px;
  overflow: auto;
}

.base-table {
  width: 100%;
  border-collapse: collapse;
  margin: 0 auto;

  thead {
    position: sticky;
    top: -1px;
    background-color: #f6f7f9;
    z-index: 1;

    th {
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 0.6px;
      font-weight: 500;
      padding: 10px 16px;
      text-align: left;
    }
  }

  &__client-info {
    display: flex;
    align-items: flex-start;

    > span {
      min-width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
      background: var(--sky-blue);
      border-radius: 100vh;
      margin-right: 16px;
    }
  }

  tbody {
    tr {
      &:hover {
        background: #fff;
        border-radius: 8px;
        box-shadow: 0px 0px 40px rgba(176, 183, 195, 0.315);
      }

      td {
        border-bottom: 1px solid #edeff5;

        &,
        * {
          letter-spacing: 0.004em;
        }
      }

      &:last-of-type {
        td {
          border-bottom: 0;
        }
      }

      &:first-child {
        td {
          padding-top: 20px;
        }
      }
    }
  }

  tr {
    th,
    td {
      font-size: 14px;
      padding: 10px 16px;
      font-weight: 400;
      letter-spacing: 0.004em;
      color: var(--black2);

      .base-drop-down {
        padding: 0;
        border: 0;
        color: var(--black2);
        font-size: 14px;

        &__arrow {
          margin-left: 7px;
        }
      }

      .base-tag {
        border-radius: 4px;
        --green2-rgb: 21, 163, 54;
      }

      small {
        font-weight: 300;
        font-size: 14px;
        margin: 2px 0;
        display: inline-block;
        color: #545860;
      }

      &:last-of-type {
        width: 180px;
      }
    }
  }
}

.dropdown {
  position: relative;
  display: inline-block;

  &-toggle {
    background: none;
    border: none;
    cursor: pointer;
  }

  &-menu {
    position: absolute;
    right: 0;
    top: 100%;
    min-width: 120px;
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
    transition: opacity 0.3s, visibility 0.3s, transform 0.3s;

    &.active {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }

    li {
      padding: 8px 12px;
      cursor: pointer;

      &:hover {
        background-color: #f5f5f5;
      }
    }
  }
}
