@import "./mixins";

.base-graph {
  height: 256px;
  display: flex;
  position: relative;
  overflow-x: auto;
  overflow: hidden;

  * {
    font-size: 12px;
  }

  &__y {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__item {
      display: flex;
      align-items: flex-end;

      span {
        display: block;
        position: relative;
        padding-right: 10px;

        &:after {
          content: "";
          height: 1px;
          width: 100vw;
          left: 50%;
          background: #edeff5;
          display: block;
          position: absolute;
        }
      }
    }
  }

  &__x {
    flex: 1;
    display: flex;
    justify-content: space-evenly;
    // justify-content: flex-start;
    position: relative;
    z-index: 1;
    transform: translate(0, 1px);

    &__item {
      border-left: 1px solid #edeff5;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;

      &__lines {
        margin-bottom: 5px;
        display: flex;
        align-items: flex-end;

        @include mobile {
          margin-right: 10px;
        }
      }

      &__line {
        height: 100px;
        display: block;
        width: 9px;
        margin: 0 4px;
        border-radius: 100vh 100vh 0 0;
      }
    }
  }
}
