.base-filter {
  margin: 24px 0;
  display: flex;

  .base-input {
    margin-bottom: 0;

    &__text-space {
      border-radius: 4px;
      font-size: 15px;
    }
  }

  .base-btn--primary {
    font-size: 15px;
    margin-left: auto;
    padding: 10px 12px;
  }

  .base-drop-down {
    margin-right: 16px;
  }

  &__search {
    width: 100%;
    max-width: 340px;
    margin-right: 16px;
    display: block;
  }
}
