.base-tag {
  font-weight: 300;
  font-size: 14px;
  padding: 6px 8px;
  background: var(--gray);
  border-radius: 4px;
  display: flex;
  align-items: center;
  width: fit-content;

  &--file {
    @extend .base-tag;
    display: inline-flex;
    margin: 0 10px 10px 0;
    padding: 11px 16px;
    background: rgba(39, 174, 96, 0.1);
    border: 1px solid rgba(39, 174, 96, 0.07);
    line-height: 1.4;
    color: #27ae60;

    &__icon {
      margin-left: 10px;
      display: flex;
      align-items: center;
      background: transparent;
      flex-shrink: 0;
    }
  }

  &--theme {
    @extend .base-tag;
    background: var(--in-orange-theme);
    color: rgb(var(--in-orange-rgb));
    display: flex;
    align-items: flex-start;
    border-radius: 8px;
  }

  &__icon {
    margin-right: 8px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }

  &--success {
    @extend .base-tag;
    background: rgba(var(--green2-rgb), 0.12);
    color: rgb(var(--green2-rgb));
  }

  &--failed {
    @extend .base-tag;
    background: rgba(var(--red-rgb), 0.12);
    color: rgb(var(--red-rgb));
  }

  &--warn {
    @extend .base-tag;
    background: rgba(var(--warning-rgb), 0.12);
    color: rgb(var(--warning-rgb));
  }

  &--info {
    @extend .base-tag;
    background: rgba(var(--sky-blue-rgb), 0.12);
    color: rgb(var(--sky-blue-rgb));
  }
}
