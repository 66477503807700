.text-secondary {
  color: var(--black2) !important;
}

.text-white {
  color: var(--white) !important;
}

.text-danger {
  color: var(--red) !important;
}

.text-dark {
  color: var(--black) !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-center {
  text-align: center;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-underline {
  text-decoration: underline;
}

.text-bold {
  font-weight: 700;
}

.text-semibold {
  font-weight: 600;
}
