.center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.pointer {
  @extend .cursor-pointer !optional;
}

.full-height {
  height: calc(100vh - 81px);
}

.strong {
  font-weight: 500;
  font-size: 16px;
  font-family: inherit;
}

.line {
  border: 1px solid #a5adbc33;
  width: 100%;
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;

  h3 {
    font-size: 20px;
    margin: 16px 0;
  }

  p {
    font-size: 14px;
    color: var(--black3);
    margin-bottom: 32px;
  }
}

.truncate-string {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100%;
}

.link-indicator {
  margin-left: 4px;
  font-size: 0.8em;
  color: #0070f3;
}
