@import "./common/mixins";

.wallet-page__wallet-balance {
  width: fit-content;
  margin-bottom: 40px;
  max-width: 660px;
  width: 100%;
  padding: 36px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include mobile {
    padding: 36px 24px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }

  &__btns {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    button {
      font-size: 14px;
      padding: 12px 33px;
      margin-left: 16px;
      white-space: nowrap;
      &:not(:first-child) {
        margin-left: 16px;
      }
    }
  }

  &__text {
    @include desktop {
      margin-right: 79px;
    }
    @include mobile {
      margin-bottom: 30px;
    }

    h3 {
      font-size: 32px;
      font-weight: 700;
      font-family: "Grillmaster ExtWide";
    }

    p {
      color: var(--black2);
    }
  }
}

.wallet-page__wallet-history {
  padding: 32px 24px;

  &__transaction-type {
    display: flex;
    align-items: center;

    &__icon {
      display: flex;
      margin-right: 16px;
    }
  }

  &__header {
    padding-bottom: 18px;
    margin-bottom: 32px;
    border-bottom: 1px solid #e4e6eb;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      font-family: "Grillmaster ExtWide";
      font-size: 20px;
    }
  }
}

.wallet-page__fund-wallet {
  .base-btn {
    font-size: 14px;
  }

  .base-tabs {
    margin-bottom: 24px;
    width: 100%;
  }

  .base-btn--primary {
    padding: 12px 36px;
    margin-top: 24px;
  }

  &__deposit-token {
    &__qr-code {
      margin-right: auto;
      margin-left: auto;
      width: fit-content;
      margin-bottom: 24px;
      display: block;
    }

    &__detail {
      padding-bottom: 16px;
      margin-bottom: 16px;
      border-bottom: 1px solid #edededcc;

      &--amount {
        @extend .wallet-page__fund-wallet__deposit-token__detail;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 0;
        padding-bottom: 0;

        > section {
          > div {
            display: flex;
            align-items: center;
            margin-top: 8px;
          }

          &:nth-child(2) {
            text-align: right;
          }
        }
      }

      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
      }

      button {
        margin-left: auto;
      }

      &__prefix-icon {
        display: flex;
        margin-right: 4px;
      }

      span {
        font-size: 14px;
      }

      > div {
        display: flex;
        align-items: center;
        margin-top: 8px;
      }
    }

    &__btns {
      display: flex;
      margin-top: 16px;

      .base-btn {
        padding: {
          right: 24px;
          left: 24px;
        }
        margin-right: 16px;
      }
    }
  }
}

.wallet-page__withdraw-funds,
.wallet-page__fund-wallet {
  .base-tag--theme {
    padding: 8px;
    border-radius: 8px;
    font-size: 14px;
    line-height: 1.43;
    margin-top: 24px;
  }
}

.wallet-page__amount-input {
  display: flex;
  align-items: center;
  position: relative;

  &:not(.no-rate) {
    > .base-input {
      margin-bottom: 0;
    }
  }

  &__flag {
    margin-right: 4px;
    border-radius: 100vh;
    overflow: hidden;
    display: flex;
  }

  .base-input {
    width: 100%;

    &__text-space {
      padding-right: 30px;
    }
  }

  .base-drop-down {
    font-size: 14px;
    border: 0;
    position: absolute;
    top: 27px;
    right: 2px;

    &__arrow {
      margin-left: 4px;
    }
  }
}

.wallet-page__account-input {
  .base-drop-down {
    border-radius: 8px;

    p {
      display: flex;
      align-items: center;
      color: var(--black2);

      .seperator {
        margin: 8px;
        color: var(--black2);
      }

      strong {
        font-weight: 400;
      }
    }
  }

  &__bank-logo {
    overflow: hidden;
    display: flex;
    border-radius: 100vh;
    margin-right: 8px;
  }
}

.wallet-page__withdraw-funds {
  &__title {
    margin-bottom: 16px;
  }

  .base-tabs {
    width: 100%;
    margin-bottom: 24px;
  }

  .base-btn--primary {
    padding: 12px 36px;
    // margin: 24px 0;
  }
}

.wallet-page__bank-transfer {
  text-align: center;

  strong {
    font-weight: 500;
  }

  &__account {
    margin: 24px 0;

    p {
      display: flex;
      align-items: center;
      justify-content: center;

      .base-btn--link {
        margin: 0 10px;
        font-size: 16px;
      }

      strong {
        font-size: 24px;
        font-weight: 500;
      }

      &:nth-child(even) {
        margin: 8px 0;
      }
    }
  }

  .base-btn {
    margin: 24px auto 0 auto;
    font-size: 14px;
  }
}
