@import "./common/mixins";

.app-navigation {
  background: var(--oxford-blue);
  padding: 24px 20px;
  min-width: 270px;
  height: 100vh;
  z-index: 4;
  overflow-x: hidden;
  overflow-y: auto;
  position: sticky;
  top: 0;

  @include mobile {
    width: 50%;
  }

  @include phone {
    width: 100%;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &__profile-btn {
    background: #fff;
    padding: 8px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    text-align: left;
    width: 100%;
    position: relative;
    cursor: pointer;

    &__logo {
      min-width: 40px;
      min-height: 40px;
      background: var(--oxford-blue);
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      border-radius: 8px;
      margin-right: 16px;
    }

    &__text {
      max-width: 100px;

      @include mobile {
        max-width: 200px;
      }

      .strong {
        color: var(--oxford-blue);
      }

      p {
        font-size: 14px;
        color: var(--black2);
      }
    }

    .base-drop-down__arrow {
      display: flex;
      flex-shrink: 0;
      margin-left: auto;
    }

    &__dropdown {
      position: absolute;
      top: 100%;
      left: 0;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      transition: 0.2s ease;
      background: #ffffff;
      box-shadow: 0px 24px 48px rgba(0, 0, 0, 0.14);
      border-radius: 6px;
      z-index: 11;
      padding: 16px;
      cursor: default;
      width: 100%;
      transform: translateY(10px);

      &[data-is-open="true"] {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
        transform: translateY(16px);
      }
    }

    &__role {
      display: flex;
      align-items: center;
      background: transparent;
      padding: 8px;
      display: flex;
      border-radius: 9px;
      width: 100%;

      .initials {
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--oxford-blue);
        border-radius: 5px;
        color: #fff;
        font-size: 9.6px;
        font-weight: 500;
        margin-right: 8px;
      }

      p {
        color: var(--oxford-blue);
        font-weight: 500;
      }

      &[data-active="true"] {
        background-color: #edf4ff;
      }

      .active-icon {
        display: flex;
        margin-left: auto;
      }
    }

    &__actions {
      margin-top: 10px;
      padding-top: 10px;
      border-top: 1px solid var(--gray);

      > p {
        color: #545860;
        margin-bottom: 18px;
        font-size: 14px;

        span {
          color: var(--oxford-blue);
          font-weight: 400;
        }
      }

      &__link {
        display: flex;
        align-items: center;
        background: transparent;
        gap: 12px;
        margin-bottom: 16px;
        font-size: 14px;
        font-weight: 400;
        color: var(--black);
      }
    }
  }

  &__links {
    padding: 40px 0;
  }

  &__sub-links {
    .app-navigation__link {
      margin-left: 56px;
      width: auto;
      font-size: 14px;

      &[data-active="true"] {
        background: #092857;
        border-radius: 6px;
        color: var(--in-orange);
      }
    }
  }

  &__link {
    font-size: 14px;
    line-height: 16px;
    padding: 16px;
    color: #e6e8ec;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    width: 100%;
    background: transparent;
    transition: 0.3s ease;

    > span {
      display: block;
      margin-right: 18px !important;
      filter: grayscale(1);
      mix-blend-mode: screen;
      letter-spacing: -0.2px;
    }

    &__drop-down-arrow {
      margin-left: auto;
      transform: translate(0, 3px);
      transform-origin: center;

      &[data-active="true"] {
        transform: translate(0, 3px) rotate(-180deg);
      }
    }

    &--compliance {
      display: flex;
      align-items: center;
      color: #777e90;
      font-size: 14px;
      background: #092857;
      box-shadow: 0px 4px 16px -8px rgba(15, 15, 15, 0.1);
      border-radius: 48px;
      padding: 8px;
      font-weight: 500;
      margin-bottom: 16px;
      border: 1px solid transparent;

      &[data-status="done"] {
        background: #092857;
        border-color: #fff;
        color: #fff;

        span {
          border-color: var(--in-orange);
          color: transparent;
          background-color: var(--in-orange);
          background-image: url("/assets/images/check.svg");
          background-position: center;
          background-repeat: no-repeat;
          background-size: 16px;
        }
      }

      &[data-status="active"] {
        background: transparent;
        border-color: var(--in-orange);
        color: #fff;

        span {
          border-color: var(--in-orange);
          color: #fff;
        }
      }

      span {
        height: 32px;
        width: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #777e90;
        border: 1px solid #777e90;
        border-radius: 100vh;
        margin-right: 16px;
        font-weight: 500;
      }
    }

    &:hover {
      color: var(--in-orange);
    }

    &[data-active="true"] {
      color: #fff;

      > span {
        filter: grayscale(0);
        mix-blend-mode: unset;
        opacity: 1;
      }
    }

    &[data-active-link="true"] {
      color: var(--in-orange);
      background: #092857;
      border-radius: 6px;

      > span {
        filter: grayscale(0);
        mix-blend-mode: unset;
        opacity: 1;
      }
    }
    &.explore {
      background: transparent;
      span {
        color: var(--white);
        border-bottom: 1px solid var(--white);
      }
    }
  }

  @include mobile {
    position: absolute;
    top: 81px;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 2;
  }
}

.app-header {
  padding: 20px 38px;
  border-bottom: 1px solid #e4e6eb;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 2;

  @include mobile {
    padding: 20px 16px;
  }

  &__heading {
    font-size: 21px;
    font-family: "Grillmaster ExtWide";
    color: var(--oxford-blue);
    font-weight: 500;
    @include phone {
      font-size: 18px;
    }
  }

  &__actions {
    display: flex;
    align-items: center;

    &__notification-btn {
      background: transparent;
    }

    &__lang-btn {
      margin-left: 24px;
      display: flex;
      align-items: center;
      padding: 8px;
      border-radius: 100vh;
      padding-right: 28px;
      position: relative;
      background: #f3f6fb;

      &__arrow {
        position: absolute;
        display: flex;
        align-items: center;
        right: 8px;
      }

      &__flag {
        height: 24px;
        width: 24px;
        display: block;
        border-radius: 100vh;
        background: var(--oxford-blue);
        margin-right: 8px;
      }

      &__label {
        font-size: 14px;
        color: var(--black2);
        @include phone {
          display: none;
        }
      }

      &__label-mobile {
        display: none;
        font-size: 14px;
        color: var(--black2);
        @include phone {
          display: block;
        }
      }
    }
  }

  .menu {
    display: none;
    @include mobile {
      display: block !important;
    }

    &__burger {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 20px;
      cursor: pointer;
      position: relative;
      z-index: 2;
      background: transparent;
      margin-right: 10px;

      &__line {
        height: 2px;
        width: 100%;
        background: var(--oxford-blue);
        border-radius: 2px;
      }

      &__line--top {
        transform: translateY(-6px);
      }

      &__line--middle {
        transform: translateY(0);
      }

      &__line--bottom {
        transform: translateY(6px);
      }

      &[data-active="true"] {
        animation: menuOpen 0.3s ease-in-out forwards;

        @keyframes menuOpen {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(90deg);
          }
        }
        .menu__line--top {
          transform: rotate(45deg) translate(-1px, 4px);
        }

        .menu__line--middle {
          opacity: 0;
        }

        .menu__line--bottom {
          transform: rotate(-45deg) translate(-1px, -4px);
        }
      }

      &[data-active="false"] {
        animation: menuClose 0.3s ease-in-out forwards;

        @keyframes menuClose {
          0% {
            transform: rotate(9deg);
          }
          100% {
            transform: rotate(0deg);
          }
        }
      }
    }
  }
}

.banner {
  background: var(--in-orange);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px;

  &__text {
    font-size: 14px;
    font-weight: 500;
    color: var(--white);
    text-align: center;
  }
}
