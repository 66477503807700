@font-face {
    font-family: 'Grillmaster ExtWide';
    src: url('Grillmaster-ExtWideThin.woff2') format('woff2'),
        url('Grillmaster-ExtWideThin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Grillmaster ExtWide';
    src: url('Grillmaster-ExtWideLight.woff2') format('woff2'),
        url('Grillmaster-ExtWideLight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Grillmaster ExtWide';
    src: url('Grillmaster-ExtWideExtraLight.woff2') format('woff2'),
        url('Grillmaster-ExtWideExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Grillmaster ExtWide';
    src: url('Grillmaster-ExtWideBlack.woff2') format('woff2'),
        url('Grillmaster-ExtWideBlack.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Grillmaster ExtWide';
    src: url('Grillmaster-ExtWideRegular.woff2') format('woff2'),
        url('Grillmaster-ExtWideRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Grillmaster ExtWide';
    src: url('Grillmaster-ExtWideMedium.woff2') format('woff2'),
        url('Grillmaster-ExtWideMedium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Grillmaster ExtWide';
    src: url('Grillmaster-ExtWideExtraLightItalic.woff2') format('woff2'),
        url('Grillmaster-ExtWideExtraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Grillmaster ExtWide';
    src: url('Grillmaster-ExtWideExtraBold.woff2') format('woff2'),
        url('Grillmaster-ExtWideExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Grillmaster ExtWide';
    src: url('Grillmaster-ExtWideBold.woff2') format('woff2'),
        url('Grillmaster-ExtWideBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Grillmaster ExtWide';
    src: url('Grillmaster-ExtWideThinItalic.woff2') format('woff2'),
        url('Grillmaster-ExtWideThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Grillmaster ExtWide';
    src: url('Grillmaster-ExtWideBoldItalic.woff2') format('woff2'),
        url('Grillmaster-ExtWideBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Grillmaster ExtWide';
    src: url('Grillmaster-ExtWideExtraBoldItalic.woff2') format('woff2'),
        url('Grillmaster-ExtWideExtraBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Grillmaster ExtWide';
    src: url('Grillmaster-ExtWideLightItalic.woff2') format('woff2'),
        url('Grillmaster-ExtWideLightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Grillmaster ExtWide';
    src: url('Grillmaster-ExtWideBlackItalic.woff2') format('woff2'),
        url('Grillmaster-ExtWideBlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Grillmaster ExtWide';
    src: url('Grillmaster-ExtWideRegularItalic.woff2') format('woff2'),
        url('Grillmaster-ExtWideRegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Grillmaster ExtWide';
    src: url('Grillmaster-ExtWideMediumItalic.woff2') format('woff2'),
        url('Grillmaster-ExtWideMediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

