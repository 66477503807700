.invite-layout {
  background-color: #f8f8f8;
  height: 100vh;

  .invite-layout__header {
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;

    &__links {
      padding: 8px 16px;
      text-align: left;
      background: rgba(var(--oxford-blue-rgb), 0.1);
      border-radius: 32px;
      display: flex;
      align-items: center;
      margin-top: 16px;
      a {
        color: var(--oxford-blue);
        font-weight: 500;
        font-size: 16px;
      }
    }
  }

  .initials {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 40px;
    min-height: 40px;
    border-radius: 50%;
    background: var(--oxford-blue);
    color: var(--white);
    font-weight: 500;
    margin: 0 auto;
  }

  &__content {
    margin-top: 62px;
    > div {
      max-width: 360px;
      margin-right: auto;
      margin-left: auto;
      padding: 40px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  &__form {
    h3 {
      margin-top: 40px;
      text-align: center;
      font-size: 22px;
      line-height: 32px;
    }
    button {
      margin-top: 40px;
      width: 100%;
    }
  }
}

.invite-status {
  text-align: center;
  max-width: 400px;
  margin: 60px auto 0 auto;

  h4 {
    margin: 24px 0 8px 0;
    font-weight: 500;
    font-size: 20px;
  }

  p {
    margin: 0 auto 24px auto;
    font-size: 14px;
    line-height: 1.71;
  }

  .base-btn {
    padding: 16px 61px;
    font-size: 14px;
    margin: 0 auto;
  }
}
