.base-tabs {
  padding: 4px 0;
  background: var(--gray);
  border-radius: 8px;
  display: flex;

  &.fit-content {
    width: fit-content;
    white-space: nowrap;

    .base-tabs__tab {
      padding: 8px 39px;
    }
  }

  &__tab {
    margin: 0 4px;
    padding: 8px;
    border-radius: 8px;
    flex: 1;
    color: var(--black2);
    font-size: 12px;
    text-align: center;
    line-height: 1.67;
    font-weight: 400;
    transition: 0.2s ease-out;
    transition-property: background;

    &[data-active="true"] {
      background: #ffffff;
      box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.1);
      font-weight: 600;
    }
  }
}
