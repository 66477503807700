$layout-breakpoint: 1024px;
$card-max-width: 560px;

@mixin desktop {
  @media (min-width: $layout-breakpoint) {
    @content;
  }
}

@mixin custom-desktop {
  @media (min-width: ($layout-breakpoint - 140px)) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $card-max-width) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: ($layout-breakpoint + 1px)) {
    @content;
  }
}

@mixin mobile-plus {
  @media (max-width: ($layout-breakpoint - 140px)) {
    @content;
  }
}

@mixin phone {
  @media (max-width: $card-max-width) {
    @content;
  }
}
