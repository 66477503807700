.documents-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 24px;
  &__item {
    @extend .base-card;
    padding: 24px;

    &__details {
      &__title {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 12px;
      }
      &__name {
        font-size: 14px;
        line-height: 16px;
      }
    }

    .line {
      margin: 24px 0;
    }
  }
}
