.setup-page {
  &__add-compliance {
    margin-top: 40px;
    padding: 32px 40px;

    h1 {
      font-family: var(--primary-font);
      font-size: 20px;
    }

    &__description {
      display: flex;
      border-top: 1px solid #e4e6eb;
      margin-top: 16px;
      justify-content: space-between;
      padding: 24px 0 0 0;

      div {
        max-width: 614px;

        button {
          font-size: 14px;
        }

        p {
          padding-bottom: 24px;
          line-height: 1.71;
          color: var(--black3);
        }
      }
    }
  }

  &__activation-pending {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    max-width: 400px;
    height: 100%;
  }

  &__compliance {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 20px;
    padding-left: 0;

    @include desktop {
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-gap: 40px;
      align-items: flex-start;
    }

    &__controls {
      display: flex;
      margin-bottom: 40px;
      @include desktop {
        flex-direction: column;
        margin-bottom: 0;
        margin-left: 40px;
      }

      button {
        background: transparent;
        width: fit-content;
        padding: 18px 24px;
        border-bottom: 2px solid #e4e6eb;
        @include desktop {
          border-left: 2px solid #e4e6eb;
          border-bottom: none;
        }
        color: var(--black3);
        transition: 0.2s ease;

        &[data-active="true"] {
          border-color: var(--in-orange);
          color: var(--black);
        }
      }
    }

    &__form {
      width: 70%;
      @include desktop {
        max-width: 400px;
      }
      @include fadeTop;

      &__heading {
        font-weight: 500;
        font-size: 20px;
        margin-bottom: 8px;
      }

      &__description {
        font-size: 14px;
        color: var(--black3);
        margin-bottom: 40px;
      }

      .base-btn--primary {
        margin-top: 40px;
      }
    }
  }

  &__create-module {
    text-align: center;
    margin-top: 130px;

    &__heading {
      font-weight: 600;
      font-size: 24px;
      max-width: 349px;
      margin: 35px auto 0 auto;
    }

    &__description {
      margin: 16px auto 24px auto;
      line-height: 1.5;
      max-width: 307px;
      color: var(--black2);

      &[data-wide] {
        max-width: 360px;
      }
    }

    &__btns {
      display: flex;
      width: fit-content;
      margin: 0 auto;
      margin-top: 24px;

      .base-btn--primary {
        padding: 10px 12px;
      }

      button {
        margin: 0 4px;
      }
    }
  }
}

.compliance-documents-page {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 24px;

  &__new-doc-btn,
  &__doc-card {
    transition: 0.4s ease-out;

    &:hover {
      box-shadow: 0px 23px 34px rgba(176, 183, 195, 0.3);
    }
  }

  &__new-doc-btn {
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(var(--in-orange-rgb), 0.3);
    background: rgba(var(--in-orange-rgb), 0.04);
    border-radius: 12px;

    span {
      margin-top: 24px;
      display: block;
      color: var(--in-orange);
    }
  }

  &__doc-card {
    padding: 24px;

    h3 {
      font-weight: 500;
      font-size: 16px;
    }

    &__doc-name {
      font-size: 14px;
      padding: 12px 0 24px 0;
      margin-bottom: 24px;
      border-bottom: 1px solid rgba(165, 173, 188, 0.2);
      display: flex;
      align-items: center;

      &__icon {
        filter: grayscale(1);
        display: flex;
        margin: 0 8px 0 0;
        height: 24px;
        min-width: 24px;
      }
    }

    &__footer {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .base-btn--link {
        text-decoration: underline;
      }
    }

    .base-tag {
      font-size: 14px;
      border-radius: 4px;
    }
  }

  &__new-document {
    .base-btn--primary {
      padding: 12px 29px;
      font-size: 14px;
    }
  }
}
